import React from 'react';
import ContentAM from '../../components/ContentAM';
import PageNavigator from "../../components/layout/PageNavigator";
import Layout from '../../components/layout/Layout';
import license from '../../data/corsi-patente/patente-B/license.json';
import ContentPatenteB from '../../components/corsi-patente/ContentPatenteB';
import BannerCompass from '../../components/BannerCompass';
import ContactSection from '../../components/home-page/ContactSection';

const PatenteB = () => {
  return (
    <Layout>
      <PageNavigator link="/corsi-patente" text="Corsi patenti" />
      {/* <ContentAM license={license} /> */}
      <ContentPatenteB/>
      <BannerCompass/>
      <ContactSection/>
    </Layout>
  )
};

export default PatenteB;
