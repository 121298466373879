import React from "react";
import Auto from "../../../static/vehicles/polo.jpg";

const ContentPatenteB = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-8 sm:px-5 py-12 pb-56 mx-auto flex flex-col">
        <div className="lg:w-full mx-auto">
          <h1 className="text-center sm:text-6xl text-4xl font-roboto font-bold tracking-tight text-gray-900">
            Corso per patente B
          </h1>
          <h2 className="lg:w-2/3 mx-auto mt-4 text-center sm:text-2xl text-xl font-sans font-normal tracking-tight text-gray-900">
            Con la patente B, potrai guidare automobili private e veicoli
            leggeri.
          </h2>
          <div className="flex flex-col sm:flex-row mt-4 sm:mt-10">
            <div className="sm:w-full sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <div className="sm:mb-4 mb-4 px-8 py-2">
                <h2 className="text-center text-2xl font-roboto font-bold tracking-tight text-gray-900">
                  Mezzo utilizzato durante il corso
                </h2>
                <p className="text-center font-sans text-xl tracking-tight text-gray-900">
                  Volkswagen Golf
                </p>
              </div>

              <div className="sm:w-full text-center sm:py-8 py-8">
                {/* Image */}
                <div className="w-84 h-64 rounded-lg inline-flex items-center justify-center bg-gray-200 text-gray-400">
                  <img
                    alt="Mezzo utilizzato per conseguire la patente B"
                    className="w-full h-full rounded-xl block inset-0"
                    src={Auto}
                  />
                </div>
                {/* Description */}
                <div className="flex flex-col items-center text-center justify-center bg-blue-100 rounded shadow-lg sm:m-12 m-2">
                  <p className="sm:p-12 p-4 title-font font-roboto font-bold tracking-tight text-gray-900 text-xl text-start leading-10">
                    Stai per compiere, o hai compiuto 18 anni? Vieni alla
                    nostra autoscuola a Pisa per conseguire l'importantissima
                    patente B. Il corso prevede una parte teorica dedicata alle
                    norme del codice della strada, i doveri del conducente, la
                    segnaletica, i limiti di velocità, la verifica del veicolo e
                    così via. <br />
                    L'esame teorico consiste in un quiz svolto al
                    computer e, una volta superato, ti verrà rilasciato in breve
                    tempo il foglio rosa. Per sei mesi potrai esercitarti alla
                    guida con i nostri istruttori professionisti o con qualcuno
                    che abbia almeno una patente B (o di categoria superiore) da
                    10 anni e che non abbia superato i 65 anni. <br />
                     Potrai sostenere
                    l'esame pratico già dopo un mese dal superamento della
                    teoria e con almeno 6 ore di guide in autoscuola. L'esame
                    pratico avviene su strada ed ha una durata di almeno
                    mezz'ora circa. Una volta superato la patente sarà tua! Se
                    invece non riuscissi a superare l’esame pratico alla prima,
                    non temere, avrai una seconda ed una terza possibilità. Se
                    andassero male tutte e tre le prove, potrai sempre estendere
                    la validità del foglio rosa di altri sei mesi senza dare
                    nuovamente l'esame di teoria, niente panico!
                  </p>
                </div>
              </div>
            </div>
            <div className="order-first sm:order-last sm:w-full sm:pl-8 sm:py-8 sm:border-l sm:border-gray-400 sm:border-t-0 border-t border-gray-400 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <h2 className="text-3xl title-font mb-4 mt-4 font-roboto font-bold tracking-tight text-gray-900">
                Età minima:
              </h2>
              <p className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                18 anni
              </p>

              <h2 className="text-3xl title-font mb-4 font-roboto font-bold tracking-tight text-gray-900">
                Abilitazione guida dei seguenti veicoli e motoveicoli:
              </h2>
              <ul className="list-disc sm:ml-8 mt-4 ml-4">
                <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Tutti gli autoveicoli di massa complessiva non superiore alle
                  3,5 tonnellate e con un numero di posti a sedere pari a un
                  massimo di 9, conducente incluso. La patente B abilita a
                  condurre uno dei suddetti veicoli anche se trainanti un
                  rimorchio leggero (fino a 750 kg)
                </li>
              </ul>

              <h2 className="text-3xl title-font mt-16 font-roboto font-bold tracking-tight text-gray-900">
                Documenti necessari:
              </h2>
              <ul className="list-disc sm:ml-8 mt-4 ml-4">
                <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Cartà d'identità candidato / Carta d'identità genitore o
                  tutore legale
                </li>
                <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Permesso di soggiorno (solo per cittadini extra UE)
                </li>
                <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  2 fototessere
                </li>
                <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Certificato anamnestico (rilasciato dal medico curante)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentPatenteB;
